.table-td-thumb[data-v-dc3c60d8] {
  display: block;
  margin: auto;
  max-width: 38px;
  max-height: 38px;
  font-size: 0.2rem;
}
.image-slot[data-v-dc3c60d8] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fa;
}